.app-header {
  background-color: #007ACC; /* Darker azure blue */
  color: white;
  padding: 10px 20px;
  text-align: center;
  font-size: 1.2em;
  position: relative;
  width: 100%;
  z-index: 1000;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  margin-top: -20px;
}

.app-header h1 {
  margin: 0;
  font-size: 2em;
}

.app-header nav ul {
  list-style: none;
  padding: 0;
  margin: 10px 0 0 0;
  display: flex;
  justify-content: center;
  gap: 15px;
}

.app-header nav ul li {
  display: inline;
}

.app-header nav ul li a {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

/* Buy Me a Coffee button styles */
.buy-coffee {
  position: absolute;
  top: 10px;
  right: 20px;
}

.coffee-button {
  height: 35px;

}

/* Responsive styles */
@media (max-width: 768px) {
  .app-header nav ul {
    flex-direction: column;
    align-items: center;
  }

  /* Move Buy Me a Coffee button to the center on mobile */
  .buy-coffee {
    position: static;
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
}
