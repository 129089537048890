.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 300px;
  margin: 0 auto;
}

.switch {
  position: absolute;
  background-color: #ffffff; /* Your blue color */
  border-radius: 20px;
  width: 50%;
  height: 40px;
  transition: all 0.3s ease;
}

.left-position {
  left: 0;
}

.right-position {
  left: 50%;
}

.left-to-right {
  animation: leftToRight 0.3s forwards;
}

.right-to-left {
  animation: rightToLeft 0.3s forwards;
}

@keyframes leftToRight {
  from {
    left: 0;
  }
  to {
    left: 50%;
  }
}

@keyframes rightToLeft {
  from {
    left: 50%;
  }
  to {
    left: 0;
  }
}

input[type="radio"] {
  display: none;
}

label {
  flex: 1;
  text-align: center;
  cursor: pointer;
  z-index: 2;
  padding: 10px;
  font-weight: bold;
}

.black-font {
  color: black;
}
