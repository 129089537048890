.app-footer {
  background-color: #007ACC;
  color: white;
  padding: 10px 20px;
  text-align: center;
  width: 100%;
  margin-top: 30px; /* Adds some space above the footer */
  position: relative; /* Ensure the footer stays within the flow of the document */
  bottom: 0;
  margin-bottom: -20px;
}

.linkedin-link {
  color: #00c2ff; /* LinkedIn blue */
  font-size: 16px;
  text-decoration: none;
  margin-top: 10px;
}