.table-container {
  width: 100%;
  max-width: 100%; /* Ensure table stays within the container */
  overflow-x: auto; /* Allow horizontal scroll on overflow */
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 10px;
  border: none;
  text-align: left;
}

@media (min-width: 768px) {
/* Assign more space to the Name column and less to the ID */
th:nth-child(1),
td:nth-child(1) {
  width: 10%; /* Smaller width for ID */
}

th:nth-child(2),
td:nth-child(2) {
  width: 40%; /* Larger width for Name */
}

th:nth-child(3),
td:nth-child(3),
th:nth-child(4),
td:nth-child(4),
th:nth-child(5),
td:nth-child(5) {
  width: 15%; /* Equal width for Points, Weight, and Score */
}

th:nth-child(6),
td:nth-child(6) {
  width: 20%; /* Width for Actions */
}
}

th {
  background-color: #f4f4f4;
}

/* Add border under each row in the main table body */
tbody tr {
  border-bottom: 1px solid #ddd; /* Line under each row */
}

tbody tr:last-child {
  border-bottom: none; /* Remove border under the last row if needed */
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  thead {
    display: none; /* Hide the table header on mobile */
  }

  tr {
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 10px;
  }

  td {
    display: block;
    text-align: right;
    position: relative;
    padding-left: 50%; /* Create space for the label */
    border: none;
    border-bottom: 1px solid #ddd;
  }

  td::before {
    content: attr(data-label); /* Add labels for each data field */
    position: absolute;
    left: 10px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    text-align: left;
    font-weight: bold;
  }

  td:last-child {
    border-bottom: 0;
  }

  td input {
    width: 100%;
    max-width: none; /* Ensure input fields take full width on mobile */
    text-align: right; /* Align text in input fields to the right */
  }
}

/* Desktop table layout */
@media (min-width: 769px) {
  .responsive-table {
    table-layout: fixed; /* Ensure consistent width for all columns */
  }

  th, td {
    text-align: center;
  }


  td input {
    max-width: 100px;
    width: 100%;
    text-align: center;
  }

  td {
    border: none; /* No borders on larger screens */
  }
}
