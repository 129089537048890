/* Base styles for all screen sizes */
.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.swot-section {
  width: 100%;
  max-width: 1200px;
  background-color: #007ACC; /* Darker azure blue */
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.swot-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}

.swot-column {
  flex: 1;
  margin: 10px;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.swot-column h2 {
  text-align: center; /* Center the text horizontally */
  margin-bottom: 25px; /* Add some space below the title */
  font-size: 1.6em; /* Optional: Adjust the font size if needed */
  font-weight: bold; /* Optional: Make the text bold */
}

/* Swot chart section */
.swot-chart-section {
  width: 100%;
  max-width: 1200px;
  background-color: #007ACC; /* Darker azure blue */
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  text-align: center;
  display: flex;
  flex-direction: column; /* Stack vertically */
  align-items: center; /* Center content horizontally */
}

.swot-chart-section h1 {
  margin-bottom: 20px;
  color: white;
  width: 100%; /* Ensure the title spans the width */
  text-align: center; /* Center the title text */
}

.swot-chart-section .swot-chart {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width: calc(100% - 40px); /* Ensure the white area is 20px from each side */
  max-width: 1160px; /* 1200px (max-width) minus 40px (20px padding on each side) */
  display: flex;
  justify-content: center; /* Center the graph inside the white box */
  align-items: center;
  margin: 0 auto; /* Center the chart within the blue section */
  overflow: hidden; /* Prevent the graph from overflowing */
}

.switch-container {
  background-color: #007ACC; /* Darker azure blue */
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin: 0px 0;
  max-width: 1200px;
  width: 100%;
}

.switch-header {
  color: #ffffff;
  margin-bottom: 10px;
  font-size: 2em;  /* Match the size of the other headers */
  text-align: center;
}

/* Responsive styles */
@media (max-width: 768px) {
  .swot-row {
    flex-direction: column;
    align-items: center;
  }

  .swot-column {
    width: calc(100% - 40px); /* Full width on smaller screens with padding */
    margin-bottom: 20px;
    margin-left: 10px; /* Maintain margin on both sides */
    margin-right: 10px;
  }

  .swot-chart-section {
    width: 100%; /* Full width on smaller screens */
    padding: 20px;
  }

  .swot-chart-section .swot-chart {
    width: calc(100% - 40px); /* Maintain 20px padding on each side */
    overflow-x: auto; /* Allow scrolling if the content overflows */
  }
}

@media (max-width: 480px) {
  .swot-column {
    width: calc(100% - 20px); /* Full width on mobile with padding */
    margin: 5px 0;
  }

  .swot-chart-section {
    width: 100%; /* Full width on mobile */
    padding: 10px;
  }

  .swot-chart-section .swot-chart {
    width: calc(100% - 20px); /* 10px padding on each side */
    overflow-x: auto; /* Allow scrolling if the content overflows */
  }
}
